import React from "react";
import { supabase } from "../Components/supabaseClient";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
} from "@mui/material";

const SignUp = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const anonKey = process.env.REACT_APP_RESEND_API_KEY;
  const navigate = useNavigate();

  const signUserUp = async (randomCode) => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    const { error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });
    if (error) {
      alert("Error signing up: " + error.message);
    } else {
      navigate("/otpverify", {
        state: { randomCode: randomCode, email: email, password: password },
      });
    }
  };

  const callEmailVerifyFunction = async () => {
    const randomCode = Math.floor(1000 + Math.random() * 9000);
    try {
      const res = await fetch("https://bxzuxbvzbgkrnunmflgc.supabase.co/functions/v1/proxy-function", { 
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          from: "FixtureFix <onboarding@fixturefix.co.uk>",
          to: [email],
          subject: "FixtureFix Email Verification",
          html: `<p>Enter the code into the verification screen and your email will be validated</strong><strong> <br/>${randomCode}</strong>`,
        }),
      });
      const data = await res.json();
      if (res.status === 200) {
        signUserUp(randomCode);
      } else {
        alert("Error: " + data.message);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Container maxWidth="xs" style={{ marginTop: "5rem" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Sign Up
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "1rem" }}
          onClick={callEmailVerifyFunction}
        >
          Sign Up
        </Button>
        <Typography variant="body2" style={{ marginTop: "1rem" }}>
          Already have an account?{" "}
          <Link href="/login" underline="hover">
            Login
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUp;
