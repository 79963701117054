import React from "react";
import {
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import InstagramIcon from '@mui/icons-material/Instagram';

const Home = () => {
  const iosLogo = () => {
    return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
        alt="Download on the App Store"
        style={{ width: "150px", margin: "1rem" }}
        onClick={() =>
          (window.location.href =
            "https://apps.apple.com/gb/app/fixturefix/id6464330205")
        }
      />
    );
  };

  const androidLogo = () => {
    return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
        alt="Get it on Google Play"
        style={{ width: "150px", margin: "1rem" }}
        onClick={() =>
          (window.location.href =
            "https://play.google.com/store/apps/details?id=com.fixturefix&pcampaignid=web_share")
        }
      />
    );
  };

  return (
    <Container>
      {/* Hero Section */}
      <Box
        sx={{
          textAlign: "center",
          marginTop: "5rem",
          color: "#ac1750",
        }}
      >
        <h1 style={{ fontSize: "3.2rem", fontWeight: "bold" }}>
          Connecting Cricket Teams for Seamless Fixture Scheduling
        </h1>
        <p style={{ fontSize: "1.4rem", marginTop: "2rem" }}>
          Create your account to get started
        </p>
        <p style={{ fontSize: "1.4rem", marginTop: "1rem" }}>
          Manage your cricket team's fixtures with ease
        </p>
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => (window.location.href = "/signup")}
            sx={{ marginRight: "1rem" }}
          >
            Get Started
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => (window.location.href = "/login")}
          >
            Login
          </Button>
        </Box>
      </Box>

      {/* About Section */}
      <Box mt={10} textAlign="center" px={2}>
        <Typography variant="h4" gutterBottom>
          About FixtureFix
        </Typography>
        <Typography variant="body1" color="textSecondary" maxWidth="md" mx="auto">
          FixtureFix is your ultimate solution for organizing cricket fixtures effortlessly. 
          Our platform allows cricket teams and clubs to list their available dates, browse 
          other teams' schedules, and communicate directly to set up matches. No more 
          back-and-forth emails or phone calls—everything you need to schedule your next 
          game is right here.
        </Typography>
      </Box>

      {/* Features Section */}
      <Box mt={10} px={2}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Features
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Interactive Calendar
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Easily add, view, and manage fixture dates.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Chat System
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Communicate in real-time with other teams.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Notifications
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Get notified of new messages and fixture confirmations.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* How It Works Section */}
      <Box mt={10} textAlign="center" px={2}>
        <Typography variant="h4" gutterBottom>
          How It Works
        </Typography>
        <Typography variant="body1" color="textSecondary" maxWidth="md" mx="auto">
          <strong>Register your team:</strong> Sign up your team or club.
          <br />
          <strong>Add dates:</strong> Add available fixture dates to the calendar.
          <br />
          <strong>Browse:</strong> Look through other teams' schedules.
          <br />
          <strong>Chat:</strong> Message teams directly and confirm your fixtures.
        </Typography>
      </Box>

      {/* App Store Links */}
      <Box mt={10} textAlign="center">
        <Grid container justifyContent="center">
          {iosLogo()}
          {androidLogo()}
        </Grid>
      </Box>

      {/* Footer */}
      <Box mt={10} py={4} textAlign="center" bgcolor="#f5f5f5">
        <Typography variant="body2" color="textSecondary">
          © 2024 FixtureFix. All rights reserved.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <a href="/privacy" style={{ margin: "0 1rem" }}>
            Privacy Policy
          </a>{" "}
          |
          <a href="/terms" style={{ margin: "0 1rem" }}>
            Terms of Service
          </a>
        </Typography>
        <Typography variant="body2" color="textSecondary" mt={1}>
          Follow us on
          <a
            href="https://www.instagram.com/fixturefixapp"
            style={{ margin: "0 0.5rem" }}
          >
            <InstagramIcon />
          </a>
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;
